import { Suspense, lazy, useState, useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { AuthPage } from '../modules/auth'
import Noticias from './public/noticias/index'
import InfoPublica from './public/informacionPublica'
// import Servicios from './public/serviciosCOG'
import CIAR from './public/CIAR'
import QuienesSomos from './public/quienesSomos'
import Federaciones from './public/Federaciones'
import Contacto from './public/contacto'
import DiaOlimpico from './public/DiaOlimpico'


import { toAbsoluteUrl } from '../../_metronic/helpers'
import { Col, Row } from 'react-bootstrap-v5';

export function PublicRoutes() {
  const BuilderPageWrapper = lazy(() => import('../views/Landing/index'))
  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth),
    handleResize = () => {
      setWidth(window.innerWidth);
    }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);
  return (
    <Suspense fallback={<FallbackView />}>
      <div className='landing-container'>
        <div className="d-flex flex-column flex-root" id="kt_app_root">
          {/*begin::Header Section*/}
          <div className="mb-0" id="home">
            {/*begin::Wrapper*/}
            <div
              className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
              style={{
                backgroundImage: "url(assets/media/svg/illustrations/landing.svg)"
              }}
            >
              {/*begin::Header*/}
              <div
                className="landing-header"
                data-kt-sticky="true"
                data-kt-sticky-name="landing-header"
                data-kt-sticky-offset="{default: '300px', lg: '400px'}"
              >
                {/*begin::Container*/}
                <div className="container">
                  {/*begin::Wrapper*/}
                  <div className="d-flex align-items-center justify-content-between">
                    {/*begin::Logo*/}
                    <div className="d-flex align-items-center flex-equal text-center"
                      style={{ justifyContent: `${width <= 991 ? 'space-between' : ''}` }}
                    >
                      {/*begin::Mobile menu toggle*/}
                      <button
                        className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none text-end"
                        id="kt_landing_menu_toggle"
                      >
                        <span className="svg-icon svg-icon-2hx text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-list"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                            />
                          </svg>

                        </span>
                      </button>
                      {/*end::Mobile menu toggle
                                            {/*begin::Logo image*/}
                      <Row style={{ justifyContent: `${width <= 800 ? 'center' : ''}` }}>
                        <Col>

                          <a href="/inicio" className='d-flex justify-content-center'>
                            <div className='text-center'>
                              <img
                                alt="Logo"
                                src={toAbsoluteUrl('/media/logos/logo-3.webp')}
                                className="logo-default h-95px h-lg-120px"
                              />
                            </div>
                            <div className='text-center'>
                              <img
                                alt="Logo"
                                src={toAbsoluteUrl('/media/logos/logo-3.webp')}
                                className="logo-sticky h-95px h-lg-120px text-center"
                              />
                            </div>
                          </a>
                        </Col>
                      </Row>
                      <div></div>
                      {/*end::Logo image*/}
                    </div>
                    {/*end::Logo*/}
                    {/*begin::Menu wrapper*/}
                    <div className="d-lg-block" id="kt_header_nav_wrapper">
                      <div
                        className="d-lg-block p-5 p-lg-0"
                        data-kt-drawer="true"
                        data-kt-drawer-name="landing-menu"
                        data-kt-drawer-activate="{default: true, lg: false}"
                        data-kt-drawer-overlay="true"
                        data-kt-drawer-width="200px"
                        data-kt-drawer-direction="start"
                        data-kt-drawer-toggle="#kt_landing_menu_toggle"
                        data-kt-swapper="true"
                        data-kt-swapper-mode="prepend"
                        data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}"
                      >
                        {/*begin::Menu*/}
                        <div
                          className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-semibold"
                          id="kt_landing_menu"
                        >
                          {/*begin::Menu item*/}
                          <div className="menu-item">
                            {/*begin::Menu link*/}
                            <a href="/inicio">
                              <span
                                className={`menu-link nav-link ${history?.location?.pathname?.includes(String('inicio')) ? 'active' : null} py-3 px-4 px-xxl-6`}
                                // onClick={() => history.push(`${`/inicio`}`)}
                                data-kt-scroll-toggle="true"
                                data-kt-drawer-dismiss="true"
                              >
                                Inicio
                              </span>
                            </a>
                            {/*end::Menu link*/}
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div className="menu-item">
                            {/*begin::Menu link*/}
                            <a href="/quienes-somos">
                              <span
                                className={`menu-link nav-link ${history?.location?.pathname?.includes(String('quienes-somos')) ? 'active' : null} py-3 px-4 px-xxl-6`}
                                // onClick={() => history.push(`${`/quienes-somos`}`)}
                                data-kt-scroll-toggle="true"
                                data-kt-drawer-dismiss="true"
                              >
                                Quiénes somos
                              </span>
                            </a>
                            {/*end::Menu link*/}
                          </div>
                          <div className="menu-item dropdown">
                            <span
                              className={`menu-link  dropdown-toggle py-3 px-4 px-xxl-6`}
                              data-kt-scroll-toggle="true"
                              data-kt-drawer-dismiss="true"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Noticias
                            </span>
                            <ul className="dropdown-menu" style={{ backgroundColor: `${width <= 800 ? ' ' : '#0e1e2e'}` }}>
                              <li>
                                <a className="dropdown-item bg-hover-opacity-0 text-hover-primary" style={{ color: '#a1a5b7', fontSize: '15px' }} href="/news/juegos-olimpicos-paris">
                                  Juegos Olímpicos París
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item bg-hover-opacity-0 text-hover-primary" style={{ color: '#a1a5b7', fontSize: '15px' }} href="/news/juegos-bolivarianos-ayacucho">
                                Juegos Bolivarianos Ayacucho
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item bg-hover-opacity-0 text-hover-primary" style={{ color: '#a1a5b7', fontSize: '15px' }} href="/news/public">
                                  Noticias
                                </a>
                              </li>
                            </ul>
                          </div>

                          {/*begin::Menu link*/}
                          {/* <div className="menu-item">
                            <a href="/news/public">
                              <span
                                className={`menu-link nav-link ${history?.location?.pathname?.includes(String('news')) ? 'active' : null} py-3 px-4 px-xxl-6`}
                                data-kt-scroll-toggle="true"
                                data-kt-drawer-dismiss="true"
                              >
                                Noticias
                              </span>
                            </a>
                          </div> */}
                          {/* // onClick={() => history.push(`${`/news/public`}`)} */}
                          {/*end::Menu link*/}
                          <div className="menu-item">
                            {/*begin::Menu link*/}
                            <a href="/federaciones-y-asociaciones">
                              <span
                                className={`menu-link nav-link ${history?.location?.pathname?.includes(String('federaciones-y-asociaciones')) ? 'active' : null} py-3 px-4 px-xxl-6`}
                                // onClick={() => history.push(`${`/informacion-publica`}`)}
                                data-kt-scroll-toggle="true"
                                data-kt-drawer-dismiss="true"
                              >
                                Federaciones
                              </span>
                            </a>
                            {/*end::Menu link*/}
                          </div>
                          {/* <div className="menu-item">
                            <a href="/CIAR">
                              <span
                                className={`menu-link nav-link ${history?.location?.pathname?.includes(String('CIAR')) ? 'active' : null} py-3 px-4 px-xxl-6`}
                                data-kt-scroll-toggle="true"
                                data-kt-drawer-dismiss="true"
                              >
                                CIAR
                              </span>
                            </a>
                          </div> */}
                          {/* <div className="menu-item">
                            <a href="/servicios">
                              <span
                                className={`menu-link nav-link ${history?.location?.pathname?.includes(String('servicios')) ? 'active' : null} py-3 px-4 px-xxl-6`}
                                data-kt-scroll-toggle="true"
                                data-kt-drawer-dismiss="true"
                              >
                                Servicios
                              </span>
                            </a>
                          </div> */}
                          <div className="menu-item">
                            {/*begin::Menu link*/}
                            <a href="/informacion-publica">
                              <span
                                className={`menu-link nav-link ${history?.location?.pathname?.includes(String('info-publica')) ? 'active' : null} py-3 px-4 px-xxl-6`}
                                // onClick={() => history.push(`${`/informacion-publica`}`)}
                                data-kt-scroll-toggle="true"
                                data-kt-drawer-dismiss="true"
                              >
                                Información Pública
                              </span>
                            </a>
                            {/*end::Menu link*/}
                          </div>
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}

                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          {/* <div className="menu-item">
                            <span
                              className={`menu-link nav-link ${history?.location?.pathname?.includes(String('legislacion')) ? 'active' : null} py-3 px-4 px-xxl-6`}
                              onClick={() => history.push(`${`/legislacion`}`)}
                              data-kt-scroll-toggle="true"
                              data-kt-drawer-dismiss="true"
                            >
                              Legislación
                            </span>
                          </div> */}
                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}

                          {/*end::Menu item*/}
                          {/*begin::Menu item*/}
                          <div className="menu-item">
                            {/*begin::Menu link*/}
                            <a href="/contacto">
                              <span
                                className={`menu-link nav-link ${history?.location?.pathname?.includes(String('contacto')) ? 'active' : null} py-3 px-4 px-xxl-6`}
                                // onClick={() => history.push(`${`/contacto`}`)}
                                data-kt-scroll-toggle="true"
                                data-kt-drawer-dismiss="true"
                              >
                                Contacto
                              </span>
                            </a>
                            {/*end::Menu link*/}
                          </div>
                          {/* <div className="menu-item">
                            <span
                              className={`menu-link nav-link ${history?.location?.pathname?.includes(String('que-hacemos')) ? 'active' : null} py-3 px-4 px-xxl-6`}
                              onClick={() => history.push(`${`/que-hacemos`}`)}
                              data-kt-scroll-toggle="true"
                              data-kt-drawer-dismiss="true"
                            >
                              Qué hacemos
                            </span>
                          </div> */}
                          {/*end::Menu item*/}
                        </div>
                        {/*end::Menu*/}
                      </div>
                    </div>
                    {/*end::Menu wrapper*/}

                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Container*/}
              </div>
              {/*end::Header*/}
              <Switch>
                <Route path='/inicio' component={BuilderPageWrapper} />
                {/* <Route path='/' component={Landing} /> */}
                <Route path='/auth' component={AuthPage} />
                <Route path='/news' component={Noticias} />
                <Route path='/quienes-somos' component={QuienesSomos} />
                <Route path='/federaciones-y-asociaciones' component={Federaciones} />
                <Route path='/contacto' component={Contacto} />
                <Route path='/info-publica' component={InfoPublica} />
                <Route path='/CIAR' component={CIAR} />
                <Route path='/dia-olimpico' component={DiaOlimpico} />
                {/* <Route path='/servicios' component={Servicios} /> */}
                <Redirect from='/informacion-publica' to='/info-publica/files' />
                <Redirect from='/news' to='/news/public' />
                <Redirect from='/news' to='/news/juegos-olimpicos-paris' />
                <Redirect exact from='/' to='/inicio' />
              </Switch>
              {/* FOOTER INICIO */}
              <div className="mb-0" >

                {/*begin::Wrapper*/}
                <div className=" pt-20" style={{ backgroundColor: '#052a55' }}>
                  {/*begin::Container*/}
                  <div className="pe-20 ps-20">
                    {/*begin::Row*/}
                    <Row className="py-10 py-lg-20 justify-content-between">
                      {/*begin::Col IZQUIERDO*/}
                      <Col xs={12} lg={4} >
                        {/*begin::Block*/}
                        <div className="mb-10">
                          {/*begin::Title*/}
                          <h2 className="text-white fs-1">OFICINAS CENTRALES</h2>
                          {/*end::Title*/}
                          {/*begin::Text*/}
                          <a href="https://ul.waze.com/ul?ll=14.60462800%2C-90.52410400&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location" target="_blank" rel="noopener noreferrer">
                            <span className="fw-normal fs-4 text-white">
                              <img
                                src={toAbsoluteUrl("/media/icons/COG/ubicacionfooter.png")}
                                alt="Logo"
                                width={40}
                                height={40}
                                className=''
                              >
                              </img>
                              &nbsp; &nbsp;26 calle 9-31 zona 5, Palacio de Los Deportes
                            </span>
                          </a>
                          {/*end::Text*/}
                        </div>
                        {/*end::Block*/}
                        {/*begin::Block*/}
                        <div >
                          {/*begin::Title*/}
                          <h2 className="text-white fs-1">CONTACTO</h2>
                          {/*end::Title*/}
                          {/*begin::Text*/}
                          <div className='p-0 m-0'>
                            <span className="fw-normal fs-4 text-white">
                              <img
                                src={toAbsoluteUrl("/media/icons/COG/telefonofooter.png")}
                                alt="Logo"
                                width={40}
                                height={40}
                                className=''
                              >
                              </img>
                              &nbsp; &nbsp;PBX: (502) 2223-9500
                            </span>
                          </div>
                          <div className='pb-5'>                          </div>
                          <span
                            className="fw-normal fs-4 text-white"
                          >
                            <img
                              src={toAbsoluteUrl("/media/icons/COG/mensajefooter2.png")}
                              alt="Logo"
                              width={40}
                              height={40}
                              className=''
                            >
                            </img>
                            &nbsp;&nbsp;congua@cog.org.gt o info@cog.gt

                          </span>
                          {/*end::Text*/}
                        </div>
                        {/*end::Block*/}
                      </Col>
                      {/*end::Col*/}
                      {/*begin::Col CENTRO*/}
                      <Col xs={12} lg={4} className='p-10'>
                        <div className='d-flex justify-content-center'>
                          <img
                            alt="Logo"
                            src={toAbsoluteUrl('/media/logos/Logo-COGblanco.webp')}
                            style={{ height: `${width <= 800 ? '25vh' : '15vw'}` }}
                          />
                        </div>
                        <Row className='text-center text-white mt-5'>
                          <Col className='m-0 p-0'>

                            <a href="https://www.facebook.com/people/Comit%C3%A9-Ol%C3%ADmpico-Guatemalteco/100095290706470/" className='text-white'>
                              <img
                                src={toAbsoluteUrl("/media/icons/COG/facebook.png")}
                                alt="Logo"
                                width={40}
                                height={40}
                              >
                              </img>
                            </a>

                          </Col>
                          <Col className='m-0 p-0'>
                            <a href="https://www.instagram.com/coguatemalteco/" className='text-white'>
                              <img
                                src={toAbsoluteUrl("/media/icons/COG/instagram.png")}
                                alt="Logo"
                                width={40}
                                height={40}
                              >
                              </img>
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={22}
                                height={22}
                                fill="currentColor"
                                className="bi bi-instagram"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                              </svg> */}
                            </a>

                          </Col>
                          <Col className='m-0 p-0'>
                            <a href="https://twitter.com/COGuatemalteco" className='text-white'>
                              <img
                                src={toAbsoluteUrl("/media/icons/COG/twitter.png")}
                                alt="Logo"
                                width={40}
                                height={40}
                              >
                              </img>
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={22}
                                height={22}
                                fill="currentColor"
                                className="bi bi-twitter-x"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                              </svg> */}
                            </a>
                          </Col>
                          <Col className='m-0 p-0'>
                            <a href="https://www.tiktok.com/@coguatemalteco" className='text-white'>
                              <img
                                src={toAbsoluteUrl("/media/icons/COG/tiktok.png")}
                                alt="Logo"
                                width={40}
                                height={40}
                              >
                              </img>
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={22}
                                height={22}
                                fill="currentColor"
                                className="bi bi-tiktok"
                                viewBox="0 0 16 16"
                              >
                                <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
                              </svg> */}
                            </a>

                          </Col>
                          <Col className='m-0 p-0'>
                            <a href="https://www.youtube.com/@COguatemalteco" className='text-white'>
                              <img
                                src={toAbsoluteUrl("/media/icons/COG/youtube.png")}
                                alt="Logo"
                                width={40}
                                height={40}
                              >
                              </img>
                            </a>

                          </Col>
                        </Row>
                      </Col>
                      {/*begin::Col DERECHO*/}
                      <Col xs={12} lg={4} className="text-end cambia-tamaño">
                        {/*begin::Navs*/}
                        <div >
                          {/*begin::Links*/}
                          <div className="d-flex fw-semibold flex-column">
                            {/*begin::Subtitle*/}
                            <h2 className="text-white fs-1">
                              MÁS SOBRE NOSOTROS
                            </h2>
                            {/*end::Subtitle*/}
                            {/*begin::Link*/}
                            <span
                              onClick={() => history.push(`${`/quienes-somos`}`)}
                              className="text-white  text-hover-primary fs-5 mb-6"
                            >
                              ¿Quiénes somos?
                            </span>
                            {/*end::Link*/}
                            {/*begin::Link*/}
                            {/* <span
                              onClick={() => history.push(`${`/legislacion`}`)}
                              className="text-white  text-hover-primary fs-5 mb-6"
                            >
                              Legislación
                            </span> */}
                            <span
                              onClick={() => history.push(`${`/news/public`}`)}
                              className="text-white  text-hover-primary fs-5 mb-6"
                            >
                              Noticias
                            </span>
                            {/*end::Link*/}
                            {/*begin::Link*/}
                            {/*end::Link*/}
                            {/*begin::Link*/}
                            <span
                              onClick={() => history.push(`${`/informacion-publica`}`)}
                              className="text-white  text-hover-primary fs-5 mb-6"
                            >
                              Información Pública
                            </span>
                            <span
                              onClick={() => history.push(`${`/contacto`}`)}
                              className="text-white  text-hover-primary fs-5 mb-6"
                            >
                              Contacto
                            </span>
                            {/*end::Link*/}
                          </div>
                          {/*end::Links*/}
                          {/*begin::Links*/}

                          {/*end::Links*/}
                        </div>
                        {/*end::Navs*/}
                      </Col>
                      {/*end::Col*/}
                    </Row>
                    {/*end::Row*/}
                  </div>
                  {/*end::Container*/}
                  {/*begin::Separator*/}
                  {/* <div className="landing-dark-separator" /> */}
                  {/*end::Separator*/}
                  {/*begin::Container*/}
                  <div className="p-5 text-center" style={{ backgroundColor: '#0075c9', width: '100%' }}>
                    <span
                      className="text-center text-white fs-4"
                    >
                      Copyright &copy; {new Date().getFullYear()} Comité Olímpico Guatemalteco

                    </span>
                    {/*end::Logo image*/}

                  </div>
                  {/*end::Container*/}
                </div>
                {/*end::Wrapper*/}
              </div>
              {/*FOOTER FIN*/}

            </div>
          </div>
        </div>
      </div>
    </Suspense>

  )
}
